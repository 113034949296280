"use client";

import { colors, Fade, Grid2 as Grid, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import useTouchable from "@v2/hooks/useTouchable";
import { uiColors } from "@v2/lib/color";
import moment from "jalali-moment";
import { forwardRef, Fragment, MouseEvent, ReactNode, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import FontAwesomeIcon from "../FontAwesomeIcon";
import LinkWithoutPrefetch from "../LinkWithoutPrefetch";
import Media from "../Media";
import CenterBox from "../styled/CenterBox";
import { MenuItemType, SubMenuItem } from "./types";

export interface DesktopMenuRef {
    closeSubMenu: () => void,
    subMenuIsOpen: () => boolean
}

interface DesktopMenuProps {
    onChangeSubmenuIsOpen?: (status: boolean) => void,
    isFloating?: boolean,
    data: MenuItemType[]
}

export default forwardRef<DesktopMenuRef, DesktopMenuProps>(function DesktopMenu(props, ref) {

    const theme = useTheme()
    const menuItemRefs = useRef({})
    const [activeMenu, setActiveMenu] = useState<null | number>(null)
    const [activeMenuRect, setActiveMenuRect] = useState<DOMRect>()

    useImperativeHandle(ref, () => ({
        closeSubMenu: () => {
            closeMenu()
        },
        subMenuIsOpen: () => {
            return activeMenu !== null
        },
    }), [activeMenu])

    // const [enterEffect, setEnterEffect] = useState<'fromOut' | 'fromRight' | 'fromLeft'>('fromOut')
    useEffect(() => {
        props.onChangeSubmenuIsOpen?.(activeMenu !== null)
    }, [activeMenu])
    const enterTimeout = useRef<NodeJS.Timeout>(undefined)
    const leaveTimeout = useRef<NodeJS.Timeout>(undefined)
    const isTouchable = useTouchable()

    const openMenu = useMemo(() => (menu) => {
        clearTimeout(enterTimeout.current)
        clearTimeout(leaveTimeout.current)

        const element: HTMLElement = menuItemRefs.current[menu]
        const rect: DOMRect = element.getBoundingClientRect()

        // switch (true) {
        //     case activeMenu === null && enterEffect !== "fromOut":
        //         setEnterEffect('fromOut')
        //         break;
        //     case activeMenu > menu && enterEffect !== "fromLeft":
        //         setEnterEffect('fromLeft')
        //         break;
        //     case activeMenu < menu && enterEffect !== "fromRight":
        //         setEnterEffect('fromRight')
        //         break;
        // }

        const timeout = activeMenu === null ? 350 : 0;
        enterTimeout.current = setTimeout(() => {
            setActiveMenu(menu)
            setActiveMenuRect(rect)
        }, timeout)
    }, [activeMenu])

    const closeMenu = useMemo(() => (timeout: number = 0) => {
        clearTimeout(enterTimeout.current)
        clearTimeout(leaveTimeout.current)

        leaveTimeout.current = setTimeout(() => {
            setActiveMenuRect(null)
            setActiveMenu(null)
        }, timeout)
    }, [activeMenu])

    const onMouseEnter = useCallback((menu: number) => () => {
        openMenu(menu)
    }, [activeMenu])

    const onClickLabel = useCallback((menu: number) => () => {
        if (isTouchable) {
            openMenu(menu)
        } else {
            closeMenu()
        }
    }, [isTouchable])

    const onClickSubMenu = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
        event.stopPropagation()
        closeMenu(0)
    }, [])


    const onMouseLeave = useCallback((menu: number) => () => {
        closeMenu(120)
    }, [activeMenu])




    const renderSubMenu = useMemo(() => (item: SubMenuItem): ReactNode => {
        switch (item.type) {
            case "image":
                return (
                    <Grid key={item.id} component="li" size={12}>
                        <Box component={LinkWithoutPrefetch} href={item.href} onClick={onClickSubMenu} sx={{
                            display: 'block', textDecoration: 'none',
                            borderRadius: 1,
                            transition: '100ms',
                            '&:hover': {
                                backgroundColor: colors.grey[100],
                                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',

                            }
                        }}>
                            <Media aspectRatio="2 / 1" height={104} id={item.media_id} />
                        </Box>
                    </Grid>
                )
            case "brand":
                return (
                    <Grid key={item.id} component="li" size={4} sx={{ maxHeight: 144 }}>
                        <Box component={LinkWithoutPrefetch} href={item.href} onClick={onClickSubMenu} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textDecoration: 'none',
                            borderRadius: 1,
                            transition: '250ms',
                            // width: 144,
                            height: 95,
                            padding: 1,
                            opacity: 0.7,
                            '&:hover': {
                                opacity: 1,
                                backgroundColor: '#fff',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                            }
                        }}>
                            <Media
                                id={item.media_id}
                                // height={40}
                                width={96}
                                aspectRatio="1.5 / 1"
                                // width={140}
                                // fill
                                // action="thumbnail"
                                background="transparent"
                            />
                        </Box>
                    </Grid>
                )
            case "shape_type":
                return (
                    <Grid key={item.id} component="li" size={2.4}>
                        <Box component={LinkWithoutPrefetch} href={item.href} onClick={onClickSubMenu} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            textDecoration: 'none',
                            borderRadius: 1,
                            transition: '250ms',
                            height: 92,
                            padding: 1,
                            border: '1px solid',
                            borderColor: colors.grey[300],

                            '&:hover': {
                                borderColor: 'transparent',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                                bgcolor: '#fff',
                            }
                        }}>
                            <Media
                                id={item.media_id}
                                aspectRatio="2 / 1"
                                height={40}
                                width={80}
                                fill
                                action="thumbnail"
                                background="transparent"
                                sx={{ maxWidth: 80 }}
                            />
                            <Typography sx={{ mt: 1, color: colors.grey[800], fontSize: 15 }}>{item.label}</Typography>
                        </Box>
                    </Grid>
                )
            case "link":
                return (
                    <Grid key={item.id} component="li" size={12}>
                        <Typography sx={{
                            color: '#474747',
                            textDecoration: 'none',
                            px: 1,
                            fontSize: 16,
                            '&:hover': {
                                color: "#BB202E"
                            }
                        }} component={LinkWithoutPrefetch}
                            href={item.href}
                            onClick={onClickSubMenu}>{item.label}</Typography>
                    </Grid>
                )
            case "more_button":
                return (
                    <Grid key={item.id} component="li" size={12}>
                        <Box sx={{
                            color: item.text_color ?? "#242424",
                            textDecoration: 'none',
                            fontSize: 16,
                            borderBottom: '1px solid transparent',
                            transition: '100ms',
                            py: 1,
                            opacity: 0.8,
                            '& i': {
                                transition: '100ms',
                            },
                            '&:hover': {
                                opacity: 1,
                                '& i': {
                                    transform: 'translateX(4px)'
                                }
                            },
                        }} component={LinkWithoutPrefetch} href={item.href} onClick={onClickSubMenu}>
                            <Typography sx={{ display: 'inline-block', mr: 1 }}>
                                {item.label}
                            </Typography>
                            <FontAwesomeIcon color={item.text_color} size={12} icon="fa-light fa-chevron-left" />
                        </Box>
                    </Grid>
                )
            case "blog":
                return (
                    <Grid key={item.id} component="li" size={12}>
                        <Box sx={{
                            display: 'flex', alignItems: 'center', textDecoration: 'none', cursor: 'pointer',
                            '&:hover': {
                                '& p:nth-of-type(1)': {
                                    color: uiColors.primary
                                }
                            },

                        }} component={LinkWithoutPrefetch} href={item.href} onClick={onClickSubMenu}>
                            <Media aspectRatio="2 / 1" width={104} id={item.media_id} alt={item.label} sx={{ borderRadius: '4px', overflow: 'hidden' }} />
                            <Box sx={{ flex: 1, ml: 2 }}>
                                <Typography sx={{ fontSize: 16, fontWeight: 400, color: "#212121" }}>{item.label}</Typography>
                                <Typography sx={{ fontSize: 14, color: '#666666', mt: 1 }}>{moment(item.created_at, 'YYYY-MM-DD').locale('fa').toNow(true)} پیش</Typography>
                            </Box>
                        </Box>
                    </Grid>
                )
            case "wiki":
                return (
                    <Grid key={item.id} component="li" size={12}>
                        <Box sx={{
                            display: 'block',
                            textDecoration: 'none',
                            borderLeft: '3px solid',
                            borderColor: colors.grey[300],
                            transition: 'border-color 200ms',
                            pl: 2,
                            cursor: 'pointer',
                            '&:hover': {
                                borderColor: uiColors.primary,
                                '& p:nth-of-type(1)': {
                                    color: uiColors.primary
                                }
                            },
                        }} component={LinkWithoutPrefetch} href={item.href} onClick={onClickSubMenu}>
                            <Typography sx={{ fontSize: 16, fontWeight: 400, color: "#212121" }}>{item.label}</Typography>
                            <Typography sx={{ fontSize: 14, color: '#666666', mt: 1 }}>{item.description}</Typography>
                        </Box>
                    </Grid>
                )
            default: return <Fragment>unsupported type</Fragment>
        }
    }, [])

    return <CenterBox sx={{
        height: 72,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
            height: 56,
        },
    }}>
        <Box sx={{
            position: 'absolute',
            bgcolor: uiColors.primary,
            height: 2,
            transition: '350ms',
            top: 70,
            [theme.breakpoints.down('md')]: {
                top: 110
            },
            ...(activeMenuRect ? {
                // transition: 'top 500ms',
                right: activeMenuRect.x,
                width: activeMenuRect.width,
            } : {
                // transition: 'top 200ms',
                right: 0,
                bgcolor: '#fff',
                width: '100%',
            })

        }} />
        <Box component="ul" sx={{
            display: 'flex',
            alignItems: 'center',
            height: "100%",
            padding: 0,
            margin: 0,
            '& > li': {
                height: '100%',
                listStyle: 'none',
                cursor: 'pointer',
                '& > .main_label': {
                    textDecoration: 'none',
                    display: 'inline-flex',
                    height: '100%',
                    alignItems: 'center',
                    // color: '#474747',
                    fontWeight: 500,
                    paddingRight: 2,
                    paddingLeft: 2,
                    position: 'relative',
                    userSelect: 'none',
                    '&:hover': {
                        color: uiColors.primary
                    }
                },
            }
        }}>

            {props.data.map((menuItem, key) => {
                const classes = [];
                const isActive = activeMenu === key
                if (isActive) classes.push('active')
                if (menuItem.children?.length) classes.push('hasChild')
                return (
                    <Box component="li" key={key}
                        onMouseEnter={!isTouchable ? onMouseEnter(key) : undefined}
                        onMouseLeave={!isTouchable ? onMouseLeave(key) : undefined}
                        ref={(el: any) => menuItemRefs.current[key] = el}
                        className={classes.join(' ')}
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Typography
                            className="main_label"
                            component={"span"}
                            sx={{
                                color: isActive ? uiColors.primary : colors.grey[800]
                            }}
                            onClick={onClickLabel(key)}

                            // menu has children && href is true
                            {...(menuItem.href ? {
                                component: LinkWithoutPrefetch,
                                href: menuItem.href
                            } : {})}
                        >{menuItem.label}</Typography>

                        {menuItem.hasDivider && (
                            <Box component="span" sx={{
                                height: 20,

                                width: '2px',
                                bgcolor: colors.grey[300],
                                display: 'inline-block'
                            }} />
                        )}

                        {(menuItem.children?.length > 0) && (
                            <Fade in={activeMenu === key} timeout={{ exit: 0 }}>
                                <Box sx={{
                                    bgcolor: '#fafafa',
                                    width: '100%',
                                    position: 'fixed',
                                    top: 72,
                                    [theme.breakpoints.down('md')]: {
                                        top: 112
                                    },
                                    right: 0,
                                    left: 0,
                                    cursor: 'default',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 20px 20px -15px',
                                }}>
                                    <Grid container component="ul" spacing={4} sx={{
                                        cursor: 'default',
                                        listStyle: 'none',
                                        maxWidth: 1400,
                                        margin: 'auto',
                                        p: 2,
                                        boxSizing: 'content-box',
                                        backgroundColor: '#fafafa',
                                    }}>
                                        {menuItem.children.map((column, key2) => {
                                            return (
                                                <Grid key={key2} size={{ xs: column.sizes.md, ...column.sizes }} component="li">
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        {column.media_id && (
                                                            <Box sx={{
                                                                borderRadius: '50%',
                                                                border: '1px solid #EBEBEB',
                                                                mr: 1
                                                            }}>
                                                                <Media id={column.media_id} height={62} width={62} sx={{}} aspectRatio="1 / 1" />
                                                            </Box>
                                                        )}
                                                        <Box sx={{ flex: 1 }}>
                                                            <Typography sx={{ fontSize: 18, fontWeight: 600 }}>{column.label}</Typography>
                                                            {column.description && (
                                                                <Typography sx={{ color: colors.grey[600], fontSize: 14, mt: '4px' }}>
                                                                    {column.description}
                                                                </Typography>
                                                            )}

                                                        </Box>
                                                    </Box>

                                                    <Grid container component="ul" spacing={2} sx={{
                                                        listStyle: 'none',
                                                        padding: 0, margin: 0, marginTop: 2,
                                                    }}>
                                                        {column.children.map((item) => renderSubMenu(item))}
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Box>
                            </Fade>
                        )}
                    </Box>
                )
            })}
        </Box>
    </CenterBox>;
})

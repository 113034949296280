"use client"

import api from "@v2/lib/api";
import { Box, colors, Grow, Skeleton, SxProps } from "@mui/material";
import Image from "next/image";
import { CSSProperties, forwardRef, Fragment, useEffect, useRef, useState } from "react";
import CenterBox from "./styled/CenterBox";
import FontAwesomeIcon from "./FontAwesomeIcon";
// import { cache } from "react";

export interface MediaProps {
    id?: null | string;

    /**
     * @default cropThumbnail
     */
    action?: "thumbnail" | "cropThumbnail";

    fill?: boolean;
    bestFill?: boolean;

    background?: string;
    opacity?: number;
    quality?: number;
    height?: number;
    width?: number;
    alt?: null | string;
    title?: null | string;

    /**
     * @default `cover`
     */
    objectFit?: CSSProperties['objectFit'],

    sx?: SxProps,
    aspectRatio?: string,

    isGlass?: boolean
}



export default forwardRef<never, MediaProps>(
    function Media({ id, action, height, width, opacity, quality, fill,
        bestFill,
        objectFit,
        background,
        alt,
        title, sx,
        aspectRatio,
        isGlass,
        ...restProps
    }, ref) {
        // const [error, setError] = useState(false);
        // const [loaded, setLoaded] = useState(false);
        // const imageRef = useRef<HTMLImageElement>()

        // useEffect(() => {
        //     if (imageRef.current?.complete) setLoaded(true)
        // }, [])

        if (!action) action = "cropThumbnail";

        const params = new URLSearchParams();

        if (height) params.set("height", height.toString());
        if (width) params.set("width", width.toString());
        if (action) params.set("action", action.toString());
        if (opacity) params.set("opacity", opacity.toString());
        if (quality) params.set("quality", quality.toString());
        if (background) params.set("background", background.toString());
        if (action === "thumbnail" || fill) params.set("fill", '1');
        if (bestFill) params.set("bestFill", '1');

        const stringifyParams = params.toString();

        const src = api.media + "/" + id + (stringifyParams ? "?" + stringifyParams : "");

        return (
            <CenterBox sx={{ maxHeight: height, width: width ?? '100%', aspectRatio: aspectRatio ?? "1 / 1", position: "relative", ...sx }}>
                <Image style={{ objectFit: objectFit ?? 'cover' }}
                    fill
                    src={src}
                    alt={alt ?? ""}
                    title={title ?? ""}
                    loading="lazy"

                    placeholder="empty"
                    blurDataURL={isGlass ? "/assets/placeholder-product.jpg" : "/assets/altImage.svg"}
                    objectFit={isGlass ? 'contain' : undefined}
                    // onLoadingComplete={() => setLoaded(true)}
                    // onError={() => setError(true)} 
                    unoptimized
                />
            </CenterBox>
        )

    });

"use client"

import { Box, Grid2 as Grid, Skeleton } from "@mui/material"
import { usePathname } from "next/navigation"
import FontAwesomeIcon from "../FontAwesomeIcon"
import Logo from "../Logo"
const HIDDEN_PATHS: RegExp[] = [
    /\/fa\/products\/.*/
]

export const HeaderLoading = ({ isAuth, viewport }: {
    isAuth: boolean,
    viewport: string
}) => {
    const pathname = usePathname()
    if (viewport === "mobile" && pathname.match(HIDDEN_PATHS[0])) {
        return null;
    }
    return (
        <Box sx={{
            height: {
                xs: 56,
                sm: 112,
                md: 72
            },
        }}>
            <Box sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                left: 0,
                bgcolor: '#fff',
                zIndex: 99999,
                boxShadow: 'rgba(0, 0, 0, 0.09) 0px 4px 11px'

            }}>
                <Grid container alignItems={{ xs: 'flex-start', md: 'center' }} sx={{
                    maxWidth: 1400,
                    height: {
                        xs: 56, sm: 112, md: 72
                    },
                    margin: 'auto',
                    boxSizing: 'content-box', px: { xs: 1, sm: 2 },
                }}>
                    <Grid size={{ xs: 4, md: 2 }} order={{ xs: 2, md: 1 }}>
                        <Box sx={{
                            display: 'flex', alignItems: 'center', justifyContent: { xs: 'flex-end', sm: 'flex-start' },
                        }}>
                            <Logo />
                        </Box>
                    </Grid>
                    <Grid size={{ sm: 12, md: 7 }} order={{ sm: 3, md: 2 }} display={{ xs: 'none', sm: 'block' }}>
                        <Box sx={{
                            display: 'flex', alignItems: 'center',
                            justifyContent: { xs: 'flex-start', md: 'center' },
                            pl: { xs: 2, md: 0 }
                        }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                                <Skeleton variant="rounded" animation="wave" width={80} height={24} sx={{ ml: 0 }} />
                                <Skeleton variant="rounded" animation="wave" width={80} height={24} sx={{ ml: 1 }} />
                                <Skeleton variant="rounded" animation="wave" width={80} height={24} sx={{ ml: 1 }} />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                <Skeleton variant="rounded" animation="wave" width={80} height={24} sx={{ ml: 0 }} />
                                <Skeleton variant="rounded" animation="wave" width={80} height={24} sx={{ ml: 1 }} />
                                <Skeleton variant="rounded" animation="wave" width={80} height={24} sx={{ ml: 1 }} />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 8, md: 3 }} order={{ xs: 1, sm: 2, md: 3 }}>
                        <Box sx={{
                            display: "flex", alignItems: "center",
                            justifyContent: { xs: 'flex-start', sm: 'flex-end' },
                            height: 56,
                        }}>
                            <Box sx={{
                                alignItems: 'center', justifyContent: 'center',
                                height: 42, width: 42, display: { xs: 'inline-flex', sm: 'none' }
                            }}>
                                <FontAwesomeIcon icon="fa-light fa-bars" />
                            </Box>
                            <Box sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', height: 42, width: 42, mr: { xs: 1, sm: 2 } }}>
                                <Skeleton variant="circular" width={32} height={32} />
                            </Box>
                            <Box sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', height: 42, width: 42, mr: { xs: 1, sm: 2 } }}>
                                <Skeleton variant="circular" width={32} height={32} />
                            </Box>
                            {isAuth ? (
                                <Box sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', height: 42, width: 42 }}>
                                    <Skeleton variant="circular" width={32} height={32} />
                                </Box>
                            ) : (
                                <Box sx={{
                                    display: 'inline-flex', alignItems: 'center', justifyContent: 'center',
                                    width: { xs: 42, sm: 75 },
                                    height: { xs: 42, sm: 38 },
                                }}>
                                    <Skeleton variant="rounded" width={75} height={38} sx={{
                                        width: { xs: '32px !important', sm: '75px !important' },
                                        height: { xs: '32px !important', sm: 38 },
                                        borderRadius: { xs: '50%', sm: 1 },
                                    }} />
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>

    )
}
"use client";

import { UserType } from "@/app/types/user";
import { Avatar, Box, Button, colors, Divider, Fade, IconButton, ListItemIcon, Menu, MenuItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import FontAwesomeIcon from "@v2/components/FontAwesomeIcon";
import { useAppSelector } from "@v2/lib/redux/hooks";
import throttle from "lodash.throttle";
import Link from "next/link";
import React, { Fragment, useEffect, useState } from "react";
import MediaV2 from "../MediaV2";

interface IProps {
    white?: boolean;
}

export default function ProfileButtonProfileButton({ white }: IProps) {
    const session = useAppSelector(s => s.general.session)
    const user = session?.data?.user ?? null
    const isAuth = session?.isAuthenticated ?? false;

    return (
        <Fragment>
            {isAuth ? (
                <AccountMenu user={user} />
            ) : (
                <LoginButton />
            )}
        </Fragment>
    )
}

function AccountMenu({ user }: {
    user: UserType
}) {
    const theme = useTheme()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(Boolean(anchorEl))

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true)
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false)
    };

    const scrollEvent = throttle(() => {
        setOpen(false)
    }, 0)

    useEffect(() => {
        window.addEventListener('scroll', scrollEvent)
        return () => {
            window.removeEventListener('scroll', scrollEvent)
        };
    }, []);

    return (
        <Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ height: 42, width: 42 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar sx={{
                        bgcolor: (!user?.avatar && !!user?.first_name?.[0]) ? colors.grey[200] : 'transparent',
                        textTransform: 'capitalize',
                        color: colors.grey[800],
                        height: 42, width: 42,
                        cursor: 'pointer'
                    }}>
                        {user?.avatar ? (
                            <MediaV2 disablePlaceholder media_id={user?.avatar.id} height={34} width={34} imageProps={{
                                fill: true
                            }} boxProps={{
                                sx: {
                                    width: 34,
                                    height: 34,
                                    borderRadius: '50%'
                                }
                            }} />
                        ) : user?.first_name?.[0] ?? (
                            <FontAwesomeIcon icon="fa-light fa-user" size={20} />
                        )}
                    </Avatar>
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                disableScrollLock
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: '4px',
                            mr: 1,
                            [theme.breakpoints.down('sm')]: {
                                mr: 'unset',
                                ml: 1,
                            },
                            '& .MuiMenuItem-root': {
                                justifyContent: 'space-between',
                                fontSize: 15,
                                minWidth: 170
                            },
                            '& .MuiListItemIcon-root': {
                                minWidth: 'unset !important',
                                ml: 3
                            },
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem component={Link} href="/fa/profile/information" prefetch={false}>
                    {user?.first_name && user?.last_name ? (
                        user?.first_name + " " + user?.last_name
                    ) : (
                        "حساب کاربری"
                    )}
                    <ListItemIcon>
                        <FontAwesomeIcon icon="fa-light fa-user" size={21} />
                    </ListItemIcon>
                </MenuItem>
                <MenuItem component={Link} href="/fa/profile/my-orders" prefetch={false}>
                    <Fragment>سفارشات</Fragment>
                    <ListItemIcon>
                        <FontAwesomeIcon icon="fa-light fa-cart-shopping" />
                    </ListItemIcon>
                </MenuItem>
                <Divider />

                <MenuItem component={Link} href="/api/auth/sign-out?callback=/fa/sign-in" prefetch={false}>
                    <Fragment>خروج</Fragment>
                    <ListItemIcon>
                        <FontAwesomeIcon icon="fa-light fa-left-from-bracket" color={colors.red[800]} />
                    </ListItemIcon>
                </MenuItem>
            </Menu>
        </Fragment>
    );
}

declare module '@mui/material/Button' {
    interface ButtonComponentOverwrite {
        component: true;
    }
}

function LoginButton() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    // const pathname = usePathname()
    const signinPagePath = '/fa/sign-in'
    // if(pathname !== '/' && pathname !== '/fa') {
    //     signinPagePath += `?callback_url=${pathname}`
    // }
    return (
        <Fade in={true}>
            {isMobile ? (
                <IconButton component={Link} href={signinPagePath} prefetch={false} sx={{
                    px: 1,
                    borderColor: colors.grey[500],
                    color: colors.grey[800],
                    width: 42,
                    height: 42

                }} color="inherit">
                    <FontAwesomeIcon icon="fa-light fa-circle-user" size={23} />
                </IconButton>
            ) : (
                <Button variant="outlined" component={Link} prefetch={false} href={signinPagePath} sx={{
                    px: 1,
                    borderColor: colors.grey[500],
                    color: colors.grey[800],
                    width: 75,

                }} color="inherit">
                    <Typography component="span" sx={{ mr: 1, color: colors.grey[80] }}>ورود</Typography>
                    <FontAwesomeIcon icon="fa-light fa-circle-user" />
                </Button>
            )}

        </Fade>
    )
}

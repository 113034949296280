"use client";

import { setHeaderIsShow } from "@/app/store/slices/general";
import { Fade, Grid2 as Grid, NoSsr, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Logo from "@v2/components/Logo";
import useScrollYChange from "@v2/hooks/useScrollYChange";
import useTouchable from "@v2/hooks/useTouchable";
import { ReactNode, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import watermarkSrc from "~/public/assets/wm.webp";
import FlexBox from "../styled/FlexBox";
import CartButton from "./CartButton";
import DesktopMenu, { DesktopMenuRef } from "./DesktopMenu";
import { HeaderLoading } from "./loading";
import MobileMenu from "./MobileMenu";
import ProfileButton from "./ProfileButton";
import SearchButton from "./SearchButton";
import { MenuItemType } from "./types";
import useOnLoadEvent from "@v2/hooks/useOnLoadEvent";
import { usePathname } from "next/navigation";

const HIDDEN_PATHS: RegExp[] = [
    /\/fa\/products\/.*/
]

interface IProps {
    xs?: boolean;
    md?: boolean;
    menuData: MenuItemType[],
    isAuth?: boolean,
    viewport?: string
}

export default function CsrHeader({ menuData, isAuth, viewport }: IProps) {
    const [showHeader, setShowHeader] = useState(true);
    const [isFloating, setIsFloating] = useState(true);
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

    const desktopMenuRef = useRef<DesktopMenuRef>(null)

    const theme = useTheme()
    const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'))
    const isSmallerThan320 = useMediaQuery(theme.breakpoints.down('xs2'))
    const isTouchable = useTouchable()
    const dispatch = useDispatch()
    const pathname = usePathname()

    if (viewport === "mobile" && pathname.match(HIDDEN_PATHS[0])) {
        return null;
    }

    let prevScrollY = null;
    useScrollYChange((scrollY) => {
        if (prevScrollY === null) prevScrollY = scrollY;

        if (isTouchable && desktopMenuRef?.current?.subMenuIsOpen()) {
            setIsSubmenuOpen(false)
            desktopMenuRef.current?.closeSubMenu?.()
        }

        const threshold = 6
        const whiteSpace = 116;
        const scrollingUp = prevScrollY > (scrollY + threshold);
        const scrollingDown = scrollY > (prevScrollY + threshold);
        const jumpedToTop = prevScrollY === 0 && scrollY === 0;

        setIsFloating(scrollY > 0)
        /**
         * init window scroll after reload or first load
         */
        if (scrollingUp || jumpedToTop) {
            // directing to top
            setShowHeader(true)
            dispatch(setHeaderIsShow(true))
        } else if (scrollingDown) {
            if (scrollY < whiteSpace) return;
            // directing to down
            if (!isSubmenuOpen) {
                setShowHeader(false)
                dispatch(setHeaderIsShow(false))
            }
        }
        prevScrollY = scrollY;
    }, [isTouchable], {
        ttl: 100
    })

    useOnLoadEvent(() => {
        const { scrollY } = window
        if (scrollY === 0) {
            setIsFloating(false)
        }
    })

    return (
        <NoSsr fallback={<HeaderLoading isAuth={isAuth} viewport={viewport} />}>
            <HeaderComponent>
                <HeaderWrapper>
                    <HeaderInner show={showHeader} isFloating={isFloating}>
                        {isSmallerThanSm ? (
                            // mobile 
                            <FlexBox sx={{
                                px: 1,
                                // pr: 1.5,
                                height: 1,
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <FlexBox>
                                    <MobileMenu data={menuData} isFloating={isFloating} />
                                    <Box sx={{ mr: 1 }}>
                                        <CartButton />
                                    </Box>
                                    <Box sx={{ mr: 1 }}>
                                        <SearchButton />
                                    </Box>
                                    <ProfileButton />
                                </FlexBox>

                                <Logo type={isSmallerThan320 ? "micro" : undefined} />
                            </FlexBox>
                        ) : (
                            <Grid container alignItems={'center'} sx={{
                                maxWidth: 1400, margin: 'auto',
                                boxSizing: 'content-box', px: { xs: 0, sm: 2 },
                                [theme.breakpoints.down('sm')]: {
                                    display: 'none'
                                }
                            }}>
                                <Grid size={{ sm: 6, md: 2 }} order={{ sm: 1, md: 1 }}>
                                    <Logo />
                                </Grid>
                                <Grid size={{ sm: 12, md: 7.5 }} order={{ sm: 3, md: 2 }}>
                                    <DesktopMenu ref={desktopMenuRef} data={menuData}
                                        onChangeSubmenuIsOpen={setIsSubmenuOpen}
                                        isFloating={isFloating} />
                                </Grid>
                                <Grid size={{ sm: 6, md: 2.5 }} order={{ sm: 2, md: 3 }}>

                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: 'flex-end' }}>
                                        <Fade in={true}>
                                            <Box sx={{ mr: 2 }} >
                                                <SearchButton />
                                            </Box>
                                        </Fade>
                                        <Fade in={true}>
                                            <Box sx={{ mr: 2, }} >
                                                <CartButton />
                                            </Box>
                                        </Fade>
                                        <Fade in={true}>
                                            <Box>
                                                <ProfileButton />
                                            </Box>
                                        </Fade>
                                    </Box>

                                </Grid>
                            </Grid>
                        )}
                    </HeaderInner>
                </HeaderWrapper>
            </HeaderComponent>
        </NoSsr>
    );
}

const HeaderComponent = (props: {
    children: ReactNode,
}) => {
    const theme = useTheme()
    return <Box sx={{
        height: 72,
        backgroundImage: `url(${watermarkSrc.src})`,
        backgroundRepeat: 'repeat',
        backgroundSize: '165px',
        backgroundPosition: '0 48px',
        [theme.breakpoints.down('md')]: {
            height: 112
        },
        [theme.breakpoints.down('sm')]: {
            height: 56
        }
    }} component="header" id="header">{props.children}</Box>
}

const HeaderWrapper = (props: {
    children: ReactNode,
    onHover?: () => void
}) => {
    return <Box onMouseEnter={props.onHover} sx={{
        zIndex: 999,
        height: 0,
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0
    }}>
        {props.children}
    </Box>
}

const HeaderInner = (props: {
    children: ReactNode,
    show: boolean,
    isFloating?: boolean
}) => {
    const theme = useTheme()
    return <Box className={props.show && "fixed_header_inner"} sx={{
        transition: '0.5s',
        width: '100%',
        bgcolor: '#fff',
        transform: 'translateY(-100%)',
        '&.fixed_header_inner': {
            transition: '0.5s cubic-bezier(0.22,0.61,0.36,1)',
            transform: 'translateY(0)',
            ...(props.isFloating ? {
                boxShadow: 'rgba(0, 0, 0, 0.09) 0px 4px 11px'
            } : {})
        },

        height: 72,
        [theme.breakpoints.down('md')]: {
            height: 112
        },
        [theme.breakpoints.down('sm')]: {
            height: 56,
        }
    }}>
        {props.children}
    </Box>
}
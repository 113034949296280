"use client";

import { Box, ButtonBase, colors, Divider, Grid2 as Grid, IconButton, SwipeableDrawer, Typography, Link as MuiLink } from "@mui/material";
import moment from "jalali-moment";
import { useRouter } from "@bprogress/next";
import { Fragment, ReactNode, useCallback, useMemo, useState } from "react";
import FontAwesomeIcon from "../FontAwesomeIcon";
import Media from "../Media";
import CenterBox from "../styled/CenterBox";
import FlexBox from "../styled/FlexBox";
import { MenuItemType, SubMenuItem } from "./types";
import LinkWithoutPrefetch from "../LinkWithoutPrefetch";


export default function MobileMenu(props: {
    onChangeSubmenuIsOpen?: (status: boolean) => void,
    isFloating?: boolean,
    data: MenuItemType[]
}) {

    // const theme = useTheme()
    const router = useRouter()

    const [activeMenu, setActiveMenu] = useState<null | MenuItemType>(null)
    const [open, setOpen] = useState(false)

    const handleCloseMenu = useCallback(() => {
        if (activeMenu) {
            setActiveMenu(null)
        } else {
            setOpen(false)
        }

    }, [activeMenu?.id])

    const handelClick = useCallback(() => {
        setActiveMenu(null)
        setOpen(false)
    }, [activeMenu?.id])


    const renderSubMenu = useMemo(() => (item: SubMenuItem): ReactNode => {
        switch (item.type) {
            case "image":
                return (
                    <Grid key={item.id} component="li" size={12}>
                        <Box component={LinkWithoutPrefetch} href={item.href} onClick={handelClick} sx={{
                            display: 'block',
                            textDecoration: 'none',
                            borderRadius: 1,
                        }}>
                            <Media aspectRatio="1 / 1" height={92} id={item.media_id} />
                        </Box>
                    </Grid>
                )
            case "brand":
                return (
                    <Grid key={item.id} component="li" size={6} sx={{ maxHeight: 144 }}>
                        <Box component={LinkWithoutPrefetch} href={item.href} onClick={handelClick} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textDecoration: 'none',
                            borderRadius: 1,
                            transition: '250ms',
                            // width: 144,
                            height: 95,
                            padding: 1,
                            opacity: 0.7,
                            '&:hover': {
                                opacity: 1,
                                backgroundColor: '#fff',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                            }
                        }}>
                            <Media
                                id={item.media_id}
                                // height={40}
                                width={96}
                                aspectRatio="1.5 / 1"
                                // width={140}
                                // fill
                                // action="thumbnail"
                                background="transparent"
                            />
                        </Box>
                    </Grid>
                )
            case "shape_type":
                return (
                    <Grid key={item.id} component="li" size={4} p={1}>
                        <Box component={LinkWithoutPrefetch} href={item.href} onClick={handelClick} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            textDecoration: 'none',
                            borderRadius: 1,
                            transition: '250ms',
                            height: 92,
                            padding: 1,
                            border: '1px solid',
                            borderColor: colors.grey[300],

                            '&:hover': {
                                borderColor: 'transparent',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                                bgcolor: '#fff',
                            }
                        }}>
                            <Media
                                id={item.media_id}
                                aspectRatio="2 / 1"
                                height={40}
                                width={80}
                                fill
                                action="thumbnail"
                                background="transparent"
                                sx={{ maxWidth: 80 }}
                            />
                            <Typography sx={{ mt: 1, color: colors.grey[800], fontSize: 15 }}>{item.label}</Typography>
                        </Box>
                    </Grid>
                )
            case "link":
                return (
                    <Grid key={item.id} component="li" size={12}>
                        <FlexBox sx={{ justifyContent: 'space-between', px: 1 }} component={LinkWithoutPrefetch} href={item.href} onClick={handelClick}>
                            <Typography sx={{
                                color: colors.grey[600],
                                textDecoration: 'none',
                                fontSize: 16,
                                height: 48,
                                // mb: 2,

                                py: 1,
                                display: 'block',
                                fontWeight: 400,
                            }} >{item.label}</Typography>
                            {/* <CenterBox>
                                <FontAwesomeIcon size={18} icon="fa-light fa-link-simple" color="#C21526" />
                            </CenterBox> */}
                        </FlexBox>
                    </Grid>

                )
            case "more_button":
                return (
                    <Grid key={item.id} component="li" size={12}>
                        <Box sx={{
                            color: item.text_color ?? "#242424",
                            textDecoration: 'none',
                            fontSize: 16,
                            borderBottom: '1px solid transparent',
                            transition: '100ms',
                            py: 1,
                            opacity: 0.8,
                            '& i': {
                                transition: '100ms',
                            },
                        }} component={LinkWithoutPrefetch} href={item.href} onClick={handelClick}>
                            <Typography sx={{ display: 'inline-block', mr: 1 }}>
                                {item.label}
                            </Typography>
                            <FontAwesomeIcon color={item.text_color} size={18} icon="fa-light fa-chevron-left" />
                        </Box>
                    </Grid>

                )
            case "blog":
                return (
                    <Grid key={item.id} component="li" size={12} mb={2}>
                        <Box sx={{
                            display: 'flex', alignItems: 'center', textDecoration: 'none', cursor: 'pointer',
                        }} component={LinkWithoutPrefetch} href={item.href} onClick={handelClick}>
                            <Media aspectRatio="2 / 1" width={120} id={item.media_id} alt={item.label} sx={{ overflow: 'hidden', borderRadius: '4px' }} />
                            <Box sx={{ flex: 1, ml: 2 }}>
                                <Typography sx={{ fontSize: 16, fontWeight: 400, color: "#212121" }}>{item.label}</Typography>
                                <Typography sx={{ fontSize: 14, color: '#666666', mt: 1 }}>{moment(item.created_at, 'YYYY-MM-DD').locale('fa').toNow(true)} پیش</Typography>
                            </Box>
                        </Box>
                    </Grid>

                )
            case "wiki":
                return (
                    <Grid key={item.id} component="li" size={12} mb={2}>
                        <Box sx={{
                            display: 'block',
                            textDecoration: 'none',
                            borderLeft: '3px solid',
                            borderColor: colors.grey[300],
                            transition: 'border-color 200ms',
                            pl: 2,
                            cursor: 'pointer',
                        }} component={LinkWithoutPrefetch} href={item.href} onClick={handelClick}>
                            <Typography sx={{ fontSize: 16, fontWeight: 400, color: "#212121" }}>{item.label}</Typography>
                            <Typography sx={{ fontSize: 14, color: '#666666', mt: 1 }}>{item.description}</Typography>
                        </Box>
                    </Grid>

                )
            default: return <Fragment>unsupported type</Fragment>
        }
    }, [])

    const MobileMenuHeader = useMemo(() => (props: { menu?: MenuItemType }) => {
        return <FlexBox aria-label="mobile_header"
            sx={{
                height: 56,
                minHeight: 56,
                px: 1,
                borderBottom: '1px solid',
                borderColor: colors.grey[300],
                justifyContent: 'space-between',
                position: 'sticky',
                top: 0,
                zIndex: 10,
                bgcolor: '#fff'
            }}>
            <FlexBox >
                <IconButton sx={{ height: 42, width: 42, position: 'relative' }} onClick={handleCloseMenu}>
                    {props.menu ? (
                        <CenterBox sx={{ position: 'absolute', inset: 0 }}>
                            <FontAwesomeIcon icon="fa-light fa-arrow-right" />
                        </CenterBox>
                    ) : (
                        <CenterBox sx={{ position: 'absolute', inset: 0 }}>
                            <FontAwesomeIcon icon="fa-light fa-xmark" />
                        </CenterBox>
                    )}
                </IconButton>
                {props.menu ? (
                    <Typography sx={{ fontWeight: 500, fontSize: 18, ml: 1 }}>
                        {props.menu?.label}
                    </Typography>
                ) : (
                    <Typography sx={{ fontWeight: 500, fontSize: 18, ml: 1 }}>
                        دسته بندی‌ها
                    </Typography>
                )}
            </FlexBox>
            {props.menu?.href && (
                <MuiLink component={LinkWithoutPrefetch} href={props.menu.href} onClick={handelClick} sx={{
                    fontSize: 12,
                    mr: 1,
                    display: 'inline-block',
                    py: 1,
                    textDecoration: 'none'
                }}>نمایش همه</MuiLink>
            )}
            {/* {!props.menu && (
                <SearchButton />
            )} */}
        </FlexBox>
    }, [activeMenu?.id])

    const handlePickMenu = useCallback((menu: MenuItemType) => () => {
        if (menu.children?.length) {
            setActiveMenu(menu)
        } else {
            router.push(menu.href)
        }
    }, [])

    return (
        <>
            <SwipeableDrawer
                keepMounted
                variant="temporary"
                slotProps={{
                    paper: {
                        sx: { left: 0 }

                    },
                    swipeArea: {
                        sx: {
                            left: 0
                        }
                    }
                }}
                anchor={'right'}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            >
                <MobileMenuHeader />

                <Box sx={{ position: "relative", height: '100%', p: 1 }}>
                    {/* <TransitionGroup > */}
                    {props.data.map((menuItem, index) => {
                        const showSubmenu = activeMenu?.id === menuItem.id;
                        return (
                            <Fragment key={index}>

                                <ButtonBase sx={{
                                    justifyContent: 'space-between',
                                    width: 1,
                                    height: 48,
                                    // mx: 1,
                                    pr: 0,
                                    pl: 2,
                                    // my: 1,
                                }} onClick={handlePickMenu(menuItem)}>
                                    <Typography>{menuItem.label}</Typography>
                                    <CenterBox sx={{ height: 42, width: 42 }}>
                                        {menuItem.children?.length ? (
                                            <FontAwesomeIcon size={18} icon="fa-light fa-angle-left" />
                                        ) : (
                                            <Fragment />
                                            // <FontAwesomeIcon size={18} color="#C21526" icon="fa-light fa-link-simple" />
                                        )}
                                    </CenterBox>
                                </ButtonBase>

                                {index + 1 !== props.data.length && (
                                    <Divider color={colors.grey[300]} sx={{ my: 1, mx: 1 }} />
                                )}
                                {menuItem.children && (
                                    <SwipeableDrawer
                                        keepMounted
                                        variant="temporary"
                                        // hideBackdrop
                                        slotProps={{
                                            paper: {
                                                sx: {
                                                    left: 0
                                                }
                                            }
                                        }}
                                        anchor={'right'}
                                        open={showSubmenu}
                                        onClose={() => setActiveMenu(null)}
                                        onOpen={() => { }}
                                    >
                                        <MobileMenuHeader menu={menuItem} />

                                        <Box sx={{ p: 2 }}>
                                            {menuItem.children?.map((child1, key2) => (
                                                <Box key={key2}>
                                                    <Typography sx={{ fontWeight: 500, fontSize: 18, mb: 2, color: colors.grey[800] }}> {child1.label} </Typography>
                                                    <Grid container component="ul" sx={{ listStyle: 'none' }} spacing={0}>
                                                        {child1.children.map((child2) => renderSubMenu(child2))}
                                                    </Grid>
                                                    {key2 + 1 !== menuItem.children.length && (
                                                        <Divider color={colors.grey[300]} sx={{ mb: 2, mt: 1 }} />
                                                    )}
                                                </Box>
                                            ))}
                                        </Box>
                                    </SwipeableDrawer>
                                )}
                            </Fragment>
                        )
                    })}
                    {/* </TransitionGroup> */}
                </Box>

            </SwipeableDrawer>
            <IconButton sx={{ height: 42, width: 42 }} onClick={() => {
                setOpen(true)
            }}>
                <FontAwesomeIcon icon="fa-light fa-bars" />
            </IconButton>
        </>
    )
}


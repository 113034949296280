"use client";

import { useEffect } from "react";

const useOnLoadEvent = (callback: () => void) => {
	useEffect(() => {
		if (document.readyState !== "complete") {
			window.addEventListener("load", callback);
			return () => window.removeEventListener("load", callback);
		} else {
			const timeout = window.setTimeout(callback, 0);
			return () => window.clearTimeout(timeout);
		}
	}, []);
};

export default useOnLoadEvent;

"use client";

import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import fitFullLogo from "@v2/assets/images/logo-fit.svg";
import microLogoSrc from "@v2/assets/images/logo-micro.svg";
import textLogoSrc from "@v2/assets/images/logo-text.svg";
import defaultLogoSrc from "@v2/assets/images/logo-vahdat.svg";
import whiteLogoSrc from "@v2/assets/images/logo-white.svg";
import Image from "next/image";
import Link from "next/link";
import { useMemo } from "react";

export interface LogoProps {
    type?: 'default' | 'text-white' | 'text-black' | 'micro' | 'mobile',
    sx?: SxProps
}

function getLogoSource(type: LogoProps['type']) {
    switch (type) {
        case "text-white":
            return whiteLogoSrc;
        case "text-black":
            return textLogoSrc;
        case "micro":
            return microLogoSrc
        case "mobile":
            return fitFullLogo
        default:
            return defaultLogoSrc;
    }
}

export default function Logo({ type, sx }: LogoProps) {
    const src = useMemo(() => getLogoSource(type), [type])

    return (
        <Box
            component={Link}
            sx={{
                height: type === "micro" ? 24 : 56,
                aspectRatio: {
                    xs: type === "micro" ? "1 / 1" : '2.3 / 1',
                    sm: type === "micro" ? "1 / 1" : '3 / 1'
                },
                position: "relative",
                display: 'block',

                ...sx
            }}
            href={`/fa`}
            prefetch={false}
            title="عینک وحدت"
        >
            <Image
                priority
                fill
                style={{objectFit: 'contain'}}
                // sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                src={src}
                alt="لوگو عینک وحدت"
            />
        </Box>
    );
}
